export class User {
  constructor(
    id,
    first_name,
    last_name,
    email,
    number,
    country,
    location,
    street,
    house_number,
    post_code,
    email_verified_at,
    agreed_terms_of_use,
    is_deleted,
    agreed_privacy_policy,
    created_at,
    updated_at
  ) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.number = number;
    this.country = country;
    this.location = location;
    this.street = street;
    this.house_number = house_number;
    this.post_code = post_code;
    this.email_verified_at = email_verified_at;
    this.agreed_terms_of_use = agreed_terms_of_use;
    this.is_deleted = is_deleted;
    this.agreed_privacy_policy = agreed_privacy_policy;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
}

export class LoginSuccess {
  constructor(success, token, user, message) {
    this.success = success;
    this.result = {
      token,
      user: new User(
        user.id,
        user.first_name,
        user.last_name,
        user.email,
        user.number,
        user.country,
        user.location,
        user.street,
        user.house_number,
        user.post_code,
        user.email_verified_at,
        user.agreed_terms_of_use,
        user.is_deleted,
        user.agreed_privacy_policy,
        user.created_at,
        user.updated_at
      ),
    };
    this.message = message;
  }
}
export class LoginFailure {
  constructor(message) {
    this.success = false;
    this.message = message || "Login failed due to an unknown error.";
  }
}
