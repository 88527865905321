<template>
  <li class="ms-0 hidden md:block">
    <a
      v-if="authStore.isLoggedIn"
      @click.prevent="handleLogout"
      class="h-[2.25rem] px-3 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500 border border-orange-500 text-white"
    >
      <i data-feather="log-out" class="h-4 w-4 me-2"></i> Logout
    </a>
    <router-link
      v-else
      to="/login"
      class="h-[2.25rem] px-3 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500 border border-orange-500 text-white"
    >
      <i data-feather="log-in" class="h-4 w-4 me-2"></i> Login
    </router-link>
  </li>
</template>

<script>
import { useAuthStore } from "@/stores/authStore";
import { inject, onMounted, watch } from "vue";
import feather from "feather-icons";

export default {
  setup() {
    const authStore = useAuthStore();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const handleLogout = async () => {
      showLoader();
      try {
        await authStore.logout();
      } finally {
        hideLoader();
      }
    };

    onMounted(() => {
      authStore.checkLoginStatus();
      if (typeof feather !== "undefined") {
        feather.replace();
      }
    });

    watch(
      () => authStore.isLoggedIn,
      () => {
        feather.replace();
      }
    );

    return {
      authStore,
      handleLogout,
    };
  },
};
</script>
