import { ref, h } from "vue";
import GlobalLoader from "@/components/GlobalLoader.vue";

export default {
  install(app) {
    const isLoading = ref(false);

    const showLoader = () => {
      isLoading.value = true;
    };

    const hideLoader = () => {
      isLoading.value = false;
    };

    app.provide("showLoader", showLoader);
    app.provide("hideLoader", hideLoader);
    app.provide("isLoading", isLoading);

    app.component("GlobalLoader", {
      render() {
        return h(GlobalLoader, { isLoading: isLoading.value });
      },
    });
  },
};
