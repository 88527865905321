<template>
  <div class="coming-soon-container">
    <!-- Centered Logo -->
    <div class="logo-container">
      <img
        src="@/assets/images_new/logos/logo_black.png"
        alt="Logo"
        class="logo"
      />
    </div>

    <!-- Animated Text -->
    <div class="text-container">
      <h1 class="main-text">Coming Soon</h1>
      <h1 class="main-text">Binnenkort Beschikbaar</h1>
    </div>

    <div class="text-container">
      <h2 class="sub-text">
        Stay tuned! We’re working hard to bring you something amazing.
      </h2>
      <h2 class="sub-text">
        Blijf op de hoogte! We werken hard om iets geweldigs te brengen.
      </h2>
    </div>

    <!-- Animated Circles for Background -->
    <div class="background-animations">
      <div class="circle circle-one"></div>
      <div class="circle circle-two"></div>
      <div class="circle circle-three"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
};
</script>

<style scoped>
/* Container for the Coming Soon Screen */
.coming-soon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #ffffff, #ffeedd, #fff5e8);
  position: relative;
  overflow: hidden;
}

/* Logo Styling */
.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 120px;
  height: auto;
  animation: fade-in 2s ease-in-out;
}

/* Main Text Styling */
.text-container {
  text-align: center;
  color: #f97316;
}

.main-text {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  animation: text-pop 1.5s ease-in-out;
}

.sub-text {
  font-size: 1.5rem;
  font-weight: 500;
  color: #f97316;
  margin: 10px 0;
  animation: text-pop 1.8s ease-in-out;
}

/* Circle Background Animations */
.background-animations .circle {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(249, 115, 22, 0.3);
  animation: move 6s ease-in-out infinite;
}

.circle-one {
  width: 300px;
  height: 300px;
  top: -100px;
  left: -50px;
  animation-delay: 0s;
}

.circle-two {
  width: 200px;
  height: 200px;
  bottom: -50px;
  right: -50px;
  animation-delay: 2s;
}

.circle-three {
  width: 250px;
  height: 250px;
  top: 30%;
  left: 70%;
  animation-delay: 4s;
}

/* Animations */
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes text-pop {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes move {
  0% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.2) translate(20px, 20px);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}
</style>
