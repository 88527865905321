<template>
  <div>
    <!-- Start Navbar -->
    <nav id="topnav" class="is-sticky bg-white shadow-md" :class="tagLine">
      <div class="container relative sm:px-0">
        <!-- Logo container-->
        <router-link v-if="navLight" to="/home" class="logo">
          <div>
            <img
              src="../../assets/images_new/logos/logo_black.png"
              class="h-[40px] sm:h-[45px] inline-block dark:hidden"
              alt="Logo"
            />
          </div>
        </router-link>
        <!-- End Logo container-->

        <!-- Start Mobile Toggle -->
        <div
          class="menu-extras"
          data-collapse="isToggle"
          aria-controls="isToggle"
          aria-expanded="false"
          @click="toggleMenu"
        >
          <div class="menu-item">
            <a
              class="navbar-toggle"
              id="isToggle"
              :class="toggle ? 'open' : ''"
            >
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>
        <!-- End Mobile Toggle -->

        <!-- Login and Cart Buttons Start -->
        <ul class="buy-button list-none mb-0">
          <search-button :searMenu2="searMenu2" :searchMenu="searchMenu" />
          <cart-dropdown />
          <menu-dropdown />
        </ul>
        <!-- Login and Cart Buttons End -->

        <!-- Mobile Navigation Menu -->
        <div id="navigation" :class="toggle ? 'block' : 'none'">
          <ul class="navigation-menu" :class="menuLight">
            <li
              class="has-submenu parent-menu-item flex justify-center items-center"
              :class="activeMenu === '/home' ? 'active' : ''"
            >
              <router-link to="/home" class="nav-link" @click="closeMenu"
                >Home</router-link
              >
            </li>

            <li
              class="has-submenu parent-menu-item shop flex justify-center items-center"
              :class="activeMenu === '/shop-grid' ? 'active' : ''"
            >
              <router-link to="/shop-grid" class="nav-link" @click="closeMenu"
                >Shop</router-link
              >
            </li>

            <li
              class="has-submenu parent-menu-item flex justify-center items-center"
              :class="activeMenu === '/aboutus' ? 'active' : ''"
            >
              <router-link to="/aboutus" class="nav-link" @click="closeMenu"
                >About Us</router-link
              >
            </li>

            <li
              class="has-submenu parent-menu-item md:hidden flex justify-center items-center text-center"
            >
              <a
                v-if="authStore.isLoggedIn"
                @click.prevent="handleLogout"
                class="has-submenu parent-menu-item flex items-center"
              >
                <i data-feather="log-out" class="h-4 w-4 me-2"></i> Logout
              </a>
              <router-link
                v-else
                to="/login"
                class="has-submenu parent-menu-item flex items-center"
                @click="closeMenu"
              >
                <i data-feather="log-in" class="h-4 w-4 me-2"></i> Login
              </router-link>
            </li>
          </ul>
        </div>
        <!-- End Mobile Navigation Menu -->
      </div>
    </nav>
    <!-- End Navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
import CartDropdown from "@/components/navbar/CartDropdown.vue";
import MenuDropdown from "@/components/navbar/MenuDropdown.vue";
import SearchButton from "@/components/navbar/SearchButton.vue";
import { useAuthStore } from "@/stores/authStore";
import { inject, onMounted, onUnmounted, ref, watch } from "vue";

export default {
  props: {
    navLight: { type: Boolean, required: true },
    menuLight: { type: String, required: true },
    searchMenu: { type: String, required: true },
    searMenu2: { type: Boolean, required: true },
    tagLine: { type: String, required: true },
  },
  name: "NavbarVue",
  components: {
    MenuDropdown,
    CartDropdown,
    SearchButton,
  },
  setup() {
    const authStore = useAuthStore();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const toggle = ref(false);

    const handleLogout = async () => {
      showLoader();
      try {
        await authStore.logout();
      } finally {
        hideLoader();
      }
    };

    const toggleMenu = () => {
      toggle.value = !toggle.value;
    };

    const closeMenu = () => {
      toggle.value = false;
    };

    const handleOutsideClick = (event) => {
      const menuElement = document.getElementById("navigation");
      const toggleElement = document.querySelector(".navbar-toggle");
      if (
        toggle.value &&
        !menuElement.contains(event.target) &&
        !toggleElement.contains(event.target)
      ) {
        closeMenu();
      }
    };

    onMounted(() => {
      document.addEventListener("click", handleOutsideClick);
      feather.replace();
      authStore.checkLoginStatus();
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleOutsideClick);
    });

    watch(
      () => authStore.isLoggedIn,
      () => feather.replace()
    );

    return {
      authStore,
      toggle,
      toggleMenu,
      closeMenu,
      handleLogout,
    };
  },
  data() {
    return {
      activeMenu: this.$route.path,
    };
  },
  mounted() {
    feather.replace();
    this.activeMenu = this.$route.path;
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("click", this.close);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("click", this.close);
  },
  methods: {
    handleScroll() {
      const navbar = document.getElementById("topnav");
      if (window.scrollY >= 50) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.toggle = false;
      }
    },
  },
};
</script>

<style scoped></style>
