// src/utils/checkoutService.js
import { toast } from "vue3-toastify";
import { ProductController } from "@/controllers/ProductController";

export const checkout = async (cartItems, showLoader, hideLoader) => {
  if (cartItems.length === 0) {
    setTimeout(() => {
      toast.error(
        "Your cart is empty! Please add some products before checking out."
      );
    }, 100);

    return;
  }

  showLoader();
  try {
    const result = await ProductController.checkout(cartItems);

    if (result.success) {
      await redirectToCheckout(result.sessionId);
    } else {
      setTimeout(() => {
        toast.error(result.message);
      }, 100);
    }
  } catch (error) {
    console.error("Checkout error: ", error);
    setTimeout(() => {
      toast.error("An error occurred during checkout.");
    }, 100);
  } finally {
    hideLoader();
  }
};

export const redirectToCheckout = async (sessionId) => {
  try {
    const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (error) {
      setTimeout(() => {
        toast.error(`Payment failed: ${error.message}`);
      }, 100);
    }
  } catch (error) {
    setTimeout(() => {
      toast.error(`Error: ${error.message}`);
    }, 100);
  }
};
