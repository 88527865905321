// src/network/models/password/VerifyOtpResponse.js

export class VerifyOtpSuccess {
  constructor(success, result, message) {
    this.success = success;
    this.result = result;
    this.message = message;
  }
}

export class VerifyOtpFailure {
  constructor(message) {
    this.success = 0;
    this.message = message;
  }
}
