// src/utils/cartState.js
import { ref } from "vue";
import {
  getCart,
  saveCart,
  addToCart as addToCartUtil,
  removeFromCart as removeFromCartUtil,
  clearCart as clearCartUtil,
} from "./cart";

const cart = ref(getCart());

export function addToCart(product) {
  addToCartUtil(product);
  cart.value = getCart();
}

export function removeFromCart(productId) {
  removeFromCartUtil(productId);
  cart.value = getCart();
}

export function clearCart() {
  clearCartUtil();
  cart.value = getCart();
}

export function updateProductQuantity(productId, quantity) {
  const currentCart = getCart();
  const product = currentCart.find((item) => item.id === productId);

  if (product) {
    product.quantity = quantity;
    saveCart(currentCart);
    cart.value = getCart();
  }
}

export function useCart() {
  return cart;
}
