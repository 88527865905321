import axios from "@/http-common";
import { AuthEndpoints } from "../api/AuthEndpoints";
import {
  SignupSuccess,
  SignupFailure,
} from "@/network/models/signup/SignupSuccess";
import {
  LoginSuccess,
  LoginFailure,
} from "@/network/models/login/LoginSuccess";
import {
  LogoutSuccess,
  LogoutFailure,
} from "@/network/models/logout/LogoutSuccess";

import {
  SendOtpSuccess,
  SendOtpFailure,
} from "@/network/models/password/SendOtpResponse";
import {
  VerifyOtpSuccess,
  VerifyOtpFailure,
} from "@/network/models/password/VerifyOtpResponse";
import {
  ResetPasswordSuccess,
  ResetPasswordFailure,
} from "@/network/models/password/ResetPasswordResponse";

export default {
  async signup(userData) {
    try {
      const response = await axios.post(AuthEndpoints.signup, userData);

      if (response.data.success === 1) {
        return new SignupSuccess(
          response.data.success,
          response.data.result.user,
          response.data.result.token,
          response.data.message
        );
      } else {
        return new SignupFailure(response.data.email, response.data.number);
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const { email, number } = error.response.data;
        return new SignupFailure(email, number, "Validation errors occurred.");
      }

      throw new Error("Signup failed: " + error.message);
    }
  },

  async login(credentials) {
    try {
      const response = await axios.post(AuthEndpoints.login, credentials);

      if (response.data.success === 1) {
        // If the response indicates success, return the success object
        return new LoginSuccess(
          response.data.success,
          response.data.result.token,
          response.data.result.user,
          response.data.message
        );
      } else {
        // For non-success responses, return LoginFailure with the message
        return new LoginFailure(response.data.message);
      }
    } catch (error) {
      // Handle specific status codes like 401 and 404
      if (error.response) {
        const status = error.response.status;
        if (status === 401) {
          // 401: Unauthorized (e.g., incorrect password)
          return new LoginFailure("Incorrect password");
        } else if (status === 404) {
          // 404: User Not Found
          return new LoginFailure("User not found");
        }
      }

      // For any other errors, log it and return a generic failure message
      console.error("Login failed: ", error);
      return new LoginFailure("An error occurred during login");
    }
  },

  async logout() {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(AuthEndpoints.logout, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success === 1) {
        return new LogoutSuccess(response.data.success, response.data.message);
      } else {
        return new LogoutFailure(response.data.message);
      }
    } catch (error) {
      throw new Error("Error during logout: " + error.message);
    }
  },

  async sendOTP(formData) {
    try {
      const response = await axios.post(AuthEndpoints.sendMailOtp, formData);
      if (response.data.success === 1) {
        return new SendOtpSuccess(
          response.data.success,
          response.data.result,
          response.data.message
        );
      } else {
        return new SendOtpFailure(response.data.message);
      }
    } catch (error) {
      throw new Error("Error during OTP sending: " + error.message);
    }
  },
  async verifyOtpForForgetPassword(formData) {
    try {
      const response = await axios.post(
        AuthEndpoints.verifyOtpForForgetPassword,
        formData
      );
      if (response.data.success === 1) {
        return new VerifyOtpSuccess(
          response.data.success,
          response.data.result,
          response.data.message
        );
      } else {
        return new VerifyOtpFailure(response.data.message);
      }
    } catch (error) {
      throw new Error("Error during OTP verification: " + error.message);
    }
  },

  async resetPasswordWithSpecialCode(formData) {
    try {
      const response = await axios.post(
        AuthEndpoints.resetPasswordWithSpecialCode,
        formData
      );

      if (response.data.success === 1) {
        return new ResetPasswordSuccess(response.data.message);
      } else {
        return new ResetPasswordFailure(response.data.message);
      }
    } catch (error) {
      throw new Error("Error during password reset: " + error.message);
    }
  },
};
