import { defineStore } from "pinia";
import { AuthController } from "@/controllers/AuthController";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    isLoggedIn: false,
    user: null,
    token: null,
  }),
  actions: {
    checkLoginStatus() {
      const token = localStorage.getItem("accessToken");
      this.isLoggedIn = !!token;
      this.token = token;
    },

    async signup(signupData) {
      const response = await AuthController.signup(signupData);
      if (response.token) {
        this.user = response.user;
        this.token = response.token;
        this.isLoggedIn = true;
        localStorage.setItem("accessToken", response.token);
      }
      this.checkLoginStatus();
      return response;
    },

    async login(credentials) {
      const response = await AuthController.login(credentials);
      if (response.token) {
        console.log("Setting token in authStore:", response.token);
        this.user = response.user;
        this.token = response.token;
        this.isLoggedIn = true;
        localStorage.setItem("accessToken", response.token);
      }
      this.checkLoginStatus();
      return response;
    },

    async logout() {
      const response = await AuthController.logout();
      if (response.success) {
        this.user = null;
        this.token = null;
        this.isLoggedIn = false;
        localStorage.removeItem("accessToken");
      }
      this.checkLoginStatus();
      return response;
    },
  },
});
