import AuthService from "@/network/services/AuthService";
import {
  SignupSuccess,
  SignupFailure,
} from "@/network/models/signup/SignupSuccess";
import {
  LoginSuccess,
  LoginFailure,
} from "@/network/models/login/LoginSuccess";
import {
  LogoutSuccess,
  LogoutFailure,
} from "@/network/models/logout/LogoutSuccess";
import {
  SendOtpSuccess,
  SendOtpFailure,
} from "@/network/models/password/SendOtpResponse";
import {
  VerifyOtpSuccess,
  VerifyOtpFailure,
} from "@/network/models/password/VerifyOtpResponse";
import {
  ResetPasswordSuccess,
  ResetPasswordFailure,
} from "@/network/models/password/ResetPasswordResponse";

export const AuthController = {
  async signup(signupData) {
    try {
      const response = await AuthService.signup(signupData);

      if (response instanceof SignupSuccess) {
        // Success case: Return user data and token
        return {
          user: response.result.user,
          token: response.result.token,
          message: response.message,
        };
      } else if (response instanceof SignupFailure) {
        return {
          error: true,
          emailErrors: response.email,
          numberErrors: response.number,
          message: response.message,
        };
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const { email, number } = error.response.data;
        return {
          error: true,
          emailErrors: email,
          numberErrors: number,
          message: "Validation errors occurred during signup.",
        };
      }

      console.error("Signup failed: ", error);
      return {
        error: true,
        message: "An error occurred during signup",
      };
    }
  },

  async login(credentials) {
    try {
      const response = await AuthService.login(credentials);

      if (response instanceof LoginSuccess) {
        return {
          user: response.result.user,
          token: response.result.token,
          message: response.message,
        };
      } else if (response instanceof LoginFailure) {
        return {
          error: true,
          message: response.message,
        };
      }
    } catch (error) {
      console.error("Login error: ", error);
      return {
        error: true,
        message: "An error occurred during login",
      };
    }
  },
  async logout() {
    try {
      const response = await AuthService.logout();

      if (response instanceof LogoutSuccess) {
        return {
          success: true,
          message: response.message,
        };
      } else if (response instanceof LogoutFailure) {
        return {
          error: true,
          message: response.message,
        };
      }
    } catch (error) {
      console.error("Logout failed: ", error);
      return {
        error: true,
        message: "An error occurred during logout",
      };
    }
  },

  async sendOTP(email) {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("process_type", "forget_password");

      const response = await AuthService.sendOTP(formData);

      if (response instanceof SendOtpSuccess) {
        localStorage.setItem("otp_code", response.result);

        return {
          success: true,
          message: response.message,
        };
      } else if (response instanceof SendOtpFailure) {
        return {
          error: true,
          message: response.message,
        };
      }
    } catch (error) {
      console.error("OTP error: ", error);
      return {
        error: true,
        message: "An error occurred while sending the OTP",
      };
    }
  },
  async verifyOtpForForgetPassword(otpCode) {
    try {
      const formData = new FormData();
      const otp_code = localStorage.getItem("otp_code");
      formData.append("otp", otpCode);
      formData.append("specialCode", otp_code);

      const response = await AuthService.verifyOtpForForgetPassword(formData);

      if (response instanceof VerifyOtpSuccess) {
        localStorage.setItem("verified_otp_code", response.result);
        localStorage.removeItem("otp_code");
        return {
          success: true,
          message: response.message,
        };
      } else if (response instanceof VerifyOtpFailure) {
        return {
          error: true,
          message: response.message,
        };
      }
    } catch (error) {
      console.error("OTP verification error: ", error);
      return {
        error: true,
        message: "An error occurred during OTP verification",
      };
    }
  },
  async resetPasswordWithSpecialCode(newPassword) {
    try {
      const formData = new FormData();

      const special_code = localStorage.getItem("verified_otp_code");
      formData.append("new_password", newPassword);
      formData.append("special_code", special_code);

      const response = await AuthService.resetPasswordWithSpecialCode(formData);

      if (response instanceof ResetPasswordSuccess) {
        localStorage.removeItem("verified_otp_code");
        return {
          success: true,
          message: response.message,
        };
      } else if (response instanceof ResetPasswordFailure) {
        return {
          error: true,
          message: response.message,
        };
      }
    } catch (error) {
      console.error("Error during password reset: ", error);
      return {
        error: true,
        message: "An error occurred during the password reset process.",
      };
    }
  },
};
