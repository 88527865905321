// src/network/models/products/ProductImage.js

export class ProductImage {
  constructor({ id, product_id, image_path, created_at, updated_at }) {
    this.id = id;
    this.productId = product_id;
    this.image_path = image_path;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
  }
}
