import axios from "@/http-common";
import { ProductEndpoints } from "@/network/api/ProductEndpoints";
import { Product } from "@/network/models/products/Product";
import { ProductFailure } from "@/network/models/products/ProductFailure";
import { CheckoutSuccess } from "@/network/models/products/CheckoutSuccess";
import { CheckoutFailure } from "@/network/models/products/CheckoutFailure";
import router from "@/router";

export const ProductService = {
  async getProduct(id) {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await axios.get(ProductEndpoints.getProduct, {
        params: { id },
        headers,
      });
      if (response.data.success === 1) {
        return new Product(response.data.result);
      } else {
        return new ProductFailure(response.data.message);
      }
    } catch (error) {
      throw new Error(`Error during product retrieval: ${error.message}`);
    }
  },
  async checkout(formData) {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await axios.post(ProductEndpoints.checkout, formData, {
        headers,
      });

      if (response.data.success === 1) {
        return new CheckoutSuccess(response.data);
      } else {
        return new CheckoutFailure({ message: "Unexpected error" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        router.push({
          name: "login",
          query: { redirect: router.currentRoute.value.fullPath },
        });
        localStorage.removeItem("accessToken");

        return new CheckoutFailure(error.response.data);
      } else {
        throw new Error("Error during checkout: " + error.message);
      }
    }
  },
  async getProducts({
    category = null,
    tag = null,
    limit = null,
    only_hero_products = null,
    page = 1, // Default to page 1 if not provided
  } = {}) {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      // Include the page parameter
      const params = {
        category,
        tag,
        limit,
        only_hero_products,
        page, // Add page to the params
      };

      const response = await axios.get(ProductEndpoints.getProducts, {
        params,
        headers,
      });

      if (response.data.success === 1) {
        const products = response.data.result.data.map(
          (item) => new Product(item)
        );

        return {
          products,
          pagination: {
            currentPage: response.data.result.current_page,
            total: response.data.result.total,
            perPage: response.data.result.per_page,
            lastPage: response.data.result.last_page,
            nextPageUrl: response.data.result.next_page_url,
          },
        };
      } else {
        return new ProductFailure(response.data.message);
      }
    } catch (error) {
      throw new Error(`Error fetching products: ${error.message}`);
    }
  },
  async getSearchProducts({ is_all = 1 } = {}) {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const params = {
        is_all, // Add page to the params
      };

      const response = await axios.get(ProductEndpoints.getProducts, {
        params,
        headers,
      });

      if (response.data.success === 1) {
        const products = response.data.result.map((item) => new Product(item));

        return {
          products,
        };
      } else {
        return new ProductFailure(response.data.message);
      }
    } catch (error) {
      throw new Error(`Error fetching products: ${error.message}`);
    }
  },
};
