<template>
  <li class="dropdown inline-block relative ps-0.5">
    <button
      data-dropdown-toggle="dropdown"
      @click.prevent="toggleCartMenu"
      class="dropdown-toggle size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500 border border-orange-500 text-white"
      type="button"
    >
      <i data-feather="shopping-cart" class="h-4 w-4"></i>
      <span class="badge" v-if="cartItems.length">{{ cartItems.length }}</span>
    </button>
    <!-- Dropdown menu -->
    <div
      class="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-64 rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800"
      :class="cartMenuOpen ? '' : 'hidden'"
    >
      <ul class="py-3 text-start" aria-labelledby="dropdownDefault">
        <!-- Total Amount -->
        <li class="flex items-center justify-between py-1.5 px-4">
          <h6 class="font-semibold mb-0">Total(€):</h6>
          <h6 class="font-semibold mb-0">€ {{ totalAmount }}</h6>
        </li>
        <li class="py-1.5 px-4 w-full m-0">
          <span class="text-center flex justify-between w-full">
            <router-link
              to="/shop-cart"
              class="w-full py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center rounded-md bg-orange-500 border border-orange-500 text-white"
              @click="closeDropdown"
              >Checkout</router-link
            >
          </span>
        </li>
        <!-- Loop through cart items -->
        <li v-for="(item, index) in cartItems" :key="index">
          <route-link
            :to="`/product_details/${item.id}`"
            @click="closeDropdown"
            class="flex items-center justify-between py-1.5 px-4"
          >
            <span class="flex items-center">
              <img
                :src="item.image"
                class="rounded shadow dark:shadow-gray-800 w-9"
                alt=""
              />
              <span class="ms-3">
                <span class="block font-semibold">{{ item.name }}</span>
                <span class="block text-sm text-slate-400"
                  >{{ item.quantity }} x {{ item.price }}</span
                >
              </span>
            </span>
            <span class="font-semibold">{{ item.total }}</span>
          </route-link>
        </li>

        <!-- Divider -->
        <li class="border-t border-gray-100 dark:border-gray-800 my-2"></li>

        <!-- View Cart and Checkout Buttons -->
      </ul>
    </div>
  </li>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useCart } from "@/utils/cartState";
import { inject } from "vue";
import { checkout } from "@/utils/checkoutService";

export default {
  name: "CartDropdown",
  setup() {
    const cart = useCart();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const cartMenuOpen = ref(false);
    const toggleCartMenu = () => {
      cartMenuOpen.value = !cartMenuOpen.value;
    };

    const closeDropdown = () => {
      cartMenuOpen.value = false;
    };

    const initiateCheckout = async () => {
      await checkout(cart.value, showLoader, hideLoader);
    };

    const initiateCheckoutAndClose = async () => {
      await initiateCheckout();
      closeDropdown();
    };

    const handleOutsideClick = (event) => {
      if (!event.target.closest(".dropdown")) {
        closeDropdown();
      }
    };

    onMounted(() => {
      document.addEventListener("click", handleOutsideClick);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleOutsideClick);
    });

    return {
      cart,
      cartMenuOpen,
      toggleCartMenu,
      closeDropdown,
      initiateCheckoutAndClose,
      cartItems: computed(() =>
        cart.value.map((item) => ({
          ...item,
          total: `€ ${item.price * item.quantity}`,
        }))
      ),
      totalAmount: computed(() =>
        cart.value.reduce((acc, item) => acc + item.price * item.quantity, 0)
      ),
    };
  },
};
</script>

<style scoped>
/* Add necessary styles */
</style>
