<template>
  <li v-if="authStore.isLoggedIn" class="inline-block relative">
    <!-- Directly show the items without dropdown -->
    <router-link
      to="/user-account"
      class="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500 border border-orange-500 text-white"
      ><i data-feather="user" class="h-4 w-4"></i
    ></router-link>
  </li>
  <li class="inline-block relative">
    <auth-button />
    <!-- Show menu items directly -->
  </li>
</template>

<script>
import AuthButton from "@/components/navbar/AuthButton.vue";
import { useAuthStore } from "@/stores/authStore";
export default {
  name: "MenuDropdown",
  components: {
    AuthButton,
  },
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
