<template>
  <comingsoon />
  <div v-if="false">
    <GlobalLoader :isLoading="isLoading" />
    <taglineWhite />
    <navbarVue :navLight="true" :searMenu2="true" :tagLine="'tagline-height'" />
    <router-view class="pt-[121px]"></router-view>
    <footerVue />
  </div>
</template>

<script>
import feather from "feather-icons";
import navbarVue from "@/components/navbar/navbar.vue";
import comingsoon from "./pages/comingsoon.vue";
import { inject } from "vue";
import taglineWhite from "@/components/tagline/tagline.vue";
import GlobalLoader from "@/components/GlobalLoader.vue";
import footerVue from "@/components/footer/footer.vue";
export default {
  name: "App",
  components: {
    taglineWhite,
    navbarVue,
    GlobalLoader,
    footerVue,
    comingsoon,
  },
  setup() {
    const isLoading = inject("isLoading");

    return {
      isLoading,
    };
  },
  mounted() {
    feather.replace();
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
