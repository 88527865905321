// src/network/models/products/Product.js
import { Category } from "@/network/models/products/Category";
import { ProductImage } from "@/network/models/products/ProductImage";

export class Product {
  constructor({
    id,
    name,
    description,
    price,
    discount_percentage,
    price_after_discount,
    unique_code,
    quantity,
    category_id,
    is_hero,
    created_at,
    updated_at,
    tags,
    category,
    images,
  }) {
    this.id = id || null;
    this.name = name || "Unknown Product";
    this.description = description || "";
    this.price = price || 0;
    this.discountPercentage = discount_percentage || 0;
    this.priceAfterDiscount = price_after_discount || price;
    this.uniqueCode = unique_code || "N/A";
    this.quantity = quantity || 0;
    this.categoryId = category_id || null;
    this.isHero = is_hero || false;
    this.createdAt = created_at || null;
    this.updatedAt = updated_at || null;
    this.tags = tags || [];
    this.category = category ? new Category(category) : null;
    this.images = Array.isArray(images)
      ? images.map((image) => new ProductImage(image))
      : [];
  }
}
