<template>
  <div>
    <!-- TAGLINE START-->
    <div class="tagline bg-slate-900">
      <div class="container relative">
        <div class="grid grid-cols-1">
          <div class="text-center">
            <h6 class="text-white font-medium">
              Free shipping in the Netherlands, orders: € 50+ 🎉
            </h6>
          </div>
        </div>
      </div>
      <!--end container-->
    </div>
    <!--end tagline-->
    <!-- TAGLINE END-->
  </div>
</template>

<script>
export default {
  name: "tagelineVue",
};
</script>

<style lang="scss" scoped></style>
