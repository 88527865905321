import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/scss/tailwind.scss";
import "@/assets/libs/@mdi/font/css/materialdesignicons.min.css";
import "@/assets/libs/tobii/css/tobii.min.css";
import VueEasyLightbox from "vue-easy-lightbox";
import router from "./router";
import axios from "./http-common";
import LoaderPlugin from "./plugins/loader";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { createPinia } from "pinia";

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(Vue3Toastify, {
  autoClose: 3000,
  position: "top-right",
});

app.use(VueEasyLightbox);
app.use(LoaderPlugin);
app.use(router);

app.config.globalProperties.$axios = axios;

app.mount("#app");
