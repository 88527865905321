<template>
  <div>
    <!-- Footer Start -->
    <footer class="footer bg-gray-900 text-gray-400 py-10">
      <div class="container mx-auto px-4">
        <div class="grid md:grid-cols-3 gap-8 items-center">
          <!-- Left Section: Company Info + Privacy and Terms -->
          <div class="text-center md:text-left space-y-2">
            <p class="text-lg font-semibold text-gray-200">
              © {{ date }} Oranje Fragrance
            </p>
            <p class="text-gray-400">
              Designed & Developed with
              <i class="mdi mdi-heart text-red-600"></i> by
              <a
                href="https://www.innovacraft.nl"
                target="_blank"
                class="text-gray-400 hover:text-white transition"
              >
                InnovaCraft
              </a>
            </p>

            <!-- Privacy Policy & Terms Links -->
            <div>
              <a
                href="/privacy"
                class="text-gray-400 hover:text-white mr-2 transition"
              >
                Privacy Policy
              </a>
              <span>|</span>
              <a
                href="/terms"
                class="text-gray-400 hover:text-white mx-2 transition"
              >
                Terms & Conditions
              </a>
            </div>
          </div>

          <!-- Middle Section: Logo -->
          <router-link to="/home" class="logo">
            <div class="text-center">
              <img
                src="@/assets/images_new/logos/logo_white.png"
                alt="Oranje Fragrance Logo"
                class="w-28 mx-auto"
              />
            </div>
          </router-link>
          <!-- Right Section: Contact Info -->
          <div class="text-center md:text-right space-y-2">
            <div
              class="flex justify-center md:justify-end items-center space-x-2"
            >
              <i class="mdi mdi-office-building-outline text-gray-400"></i>
              <p class="text-sm">KvK: 76006182</p>
            </div>
            <div
              class="flex justify-center md:justify-end items-center space-x-2"
            >
              <i class="mdi mdi-map-marker-outline text-gray-400"></i>
              <p class="text-sm">Lijnbaan 30-D, 8011 AR, The Netherlands</p>
            </div>
            <div
              class="flex justify-center md:justify-end items-center space-x-2"
            >
              <i class="mdi mdi-email-outline text-gray-400"></i>
              <a
                href="mailto:info@oranje-fragrance.nl"
                class="text-sm text-gray-400 hover:text-white transition"
              >
                info@oranje-fragrance.nl
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer End -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  name: "footerVue",
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
  mounted() {
    feather.replace();
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer a {
  transition: color 0.3s ease;
}

.footer i {
  font-size: 1.25rem;
}
</style>
