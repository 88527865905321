export class SendOtpSuccess {
  constructor(success, result, message) {
    this.success = success;
    this.result = result;
    this.message = message;
  }
}

export class SendOtpFailure {
  constructor(message) {
    this.success = 0;
    this.message = message;
  }
}
