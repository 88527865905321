// src/utils/cart.js

const CART_KEY = "cart";

export function getCart() {
  let cart = JSON.parse(localStorage.getItem(CART_KEY));

  if (!Array.isArray(cart)) {
    cart = [];
  }

  return cart;
}

export function saveCart(cart) {
  localStorage.setItem(CART_KEY, JSON.stringify(cart));
}

export function addToCart(product) {
  const cart = getCart();
  const existingProduct = cart.find((item) => item.id === product.id);

  if (existingProduct) {
    existingProduct.quantity += product.quantity;
  } else {
    cart.push(product);
  }

  saveCart(cart);
}

export function removeFromCart(productId) {
  let cart = getCart();
  cart = cart.filter((item) => item.id !== productId);
  saveCart(cart);
}

export function clearCart() {
  localStorage.setItem("cart", JSON.stringify([]));
}

export function updateProductQuantity(productId, quantity) {
  const cart = getCart();
  const product = cart.find((item) => item.id === productId);

  if (product) {
    product.quantity = quantity;
    if (product.quantity <= 0) {
      removeFromCart(productId);
    } else {
      saveCart(cart);
    }
  }
}
