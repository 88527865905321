const BASE_URL = process.env.VUE_APP_BASE_URL;

export const AuthEndpoints = {
  login: `${BASE_URL}/login`,
  signup: `${BASE_URL}/signup`,
  logout: `${BASE_URL}/logout`,
  sendMailOtp: `${BASE_URL}/sendMailOtp`,
  verifyOtpForForgetPassword: `${BASE_URL}/verifyOtpForForgetPassword`,
  resetPasswordWithSpecialCode: `${BASE_URL}/resetPasswordWithSpecialCode`,
  getUserData: `${BASE_URL}/get_user_data`,
  updateUser: `${BASE_URL}/user/update`,
  updatePassword: `${BASE_URL}/updatePassword`,
};
