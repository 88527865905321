<template>
  <li class="dropdown inline-block relative pe-1">
    <button
      v-if="searMenu2"
      data-dropdown-toggle="dropdown"
      class="dropdown-toggle align-middle inline-flex"
      type="button"
      @click="toggleParagraph"
      ref="searchButton"
    >
      <i data-feather="search" class="size-5 dark-icon" :class="searchMenu"></i>
    </button>

    <!-- Dropdown menu -->
    <div
      class="dropdown-menu z-50 max-h-60 overflow-auto rounded-lg bg-white dark:bg-slate-800 shadow-lg dark:shadow-gray-800 transition-all ease-in-out duration-200 transform"
      :class="{
        'hidden opacity-0 scale-95': searchmenu === false,
        'block opacity-100 scale-100': searchmenu === true,
      }"
      ref="dropdownMenu"
    >
      <div class="relative p-3">
        <input
          v-model="searchQuery"
          @input="filterItems"
          type="text"
          class="h-9 px-3 pr-10 w-full border border-gray-300 dark:border-slate-700 rounded-md focus:ring-2 focus:ring-orange-500 focus:outline-none dark:bg-slate-800 dark:text-white transition-all duration-150 ease-in-out"
          placeholder="Search for products..."
        />
      </div>

      <!-- Search Results -->
      <ul
        v-if="filteredItems.length"
        class="divide-y divide-gray-100 dark:divide-slate-700"
      >
        <li
          v-for="(item, index) in filteredItems"
          :key="index"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-700 flex justify-between items-center transition-all ease-in-out duration-150"
          @click="selectItem(item)"
        >
          <span class="truncate font-medium">{{ item.name }}</span>
          <span class="text-xs text-gray-400 dark:text-gray-500">{{
            item.category
          }}</span>
        </li>
      </ul>

      <div v-else class="px-4 py-2 text-gray-500 dark:text-gray-400">
        No results found
      </div>
    </div>
  </li>
</template>

<script>
import { ProductController } from "@/controllers/ProductController";

export default {
  props: {
    searMenu2: {
      type: Boolean,
      required: true,
    },
    searchMenu: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchmenu: false,
      searchQuery: "",
      products: [], // This will hold the fetched products
      filteredItems: [],
      loading: true,
      error: null,
    };
  },
  methods: {
    async fetchProducts() {
      this.loading = true;
      try {
        const response = await ProductController.fetchSearchProducts();
        if (response.success) {
          this.products = response.products.map((product) => ({
            id: product.id,
            name: product.name,
            category: product.category ? product.category.name : "Unknown",
          }));
          this.filteredItems = this.products; // Initialize filtered items with all products
        } else {
          this.error = response.message || "Failed to load products.";
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        this.error = "An unexpected error occurred while loading products.";
      } finally {
        this.loading = false;
      }
    },
    toggleParagraph() {
      this.searchmenu = !this.searchmenu;
      if (this.searchmenu) {
        this.filteredItems = this.products; // Show all items initially
      }
    },
    filterItems() {
      const query = this.searchQuery.trim().toLowerCase();
      if (query) {
        this.filteredItems = this.products.filter((item) =>
          item.name.toLowerCase().includes(query)
        );
      } else {
        this.filteredItems = this.products; // Show all items when search is empty
      }
    },
    selectItem(item) {
      // Ensure the dropdown closes and the navigation happens properly
      this.searchmenu = false;
      this.$router.push({ name: "ProcutS", params: { id: item.id } });
    },
    handleClickOutside(event) {
      // Prevent dropdown from closing if clicked inside the button or dropdown
      if (
        this.searchmenu &&
        !this.$refs.dropdownMenu.contains(event.target) &&
        !this.$refs.searchButton.contains(event.target)
      ) {
        this.searchmenu = false;
      }
    },
  },
  async mounted() {
    await this.fetchProducts();
    document.addEventListener("click", this.handleClickOutside); // Add listener for clicks outside
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside); // Clean up listener
  },
};
</script>

<style scoped>
.dropdown-menu {
  background-color: white;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  position: absolute;
}

@media (min-width: 1024px) {
  .dropdown-menu {
    width: 400px;
    right: 0;
  }
}

@media (max-width: 768px) {
  .dropdown-menu {
    position: fixed;
    left: 0;
    right: 0;
    width: 100vw;
    margin: 0;
    z-index: 1000;
    margin-top: 25px;
  }
}

input::placeholder {
  color: #6b7280;
}

.dark .dropdown-menu {
  background-color: #1e293b;
  border-color: #4b5563;
}

.dropdown-menu ul {
  max-height: 240px;
  overflow-y: auto;
}

.dropdown-menu li {
  font-size: 0.875rem;
  padding: 0.75rem;
}

.dropdown-menu li:hover {
  background-color: #f3f4f6;
  cursor: pointer;
}

.dark .dropdown-menu li:hover {
  background-color: #374151;
}

input:focus {
  border-color: #f97316;
  box-shadow: 0 0 0 2px rgba(249, 115, 22, 0.4);
  transition: box-shadow 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
</style>
