// src/network/models/products/Category.js

export class Category {
  constructor({ id, name, image, is_special, created_at, updated_at }) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.isSpecial = is_special;
    this.createdAt = created_at;
    this.updatedAt = updated_at;
  }
}
