// src/network/models/products/CheckoutSuccess.js
import { Product } from "@/network/models/products/Product";

export class CheckoutSuccess {
  constructor(data) {
    this.success = data.success;
    this.message = data.message;
    this.result = data.result ? new CheckoutResult(data.result) : null;
  }
}

class CheckoutResult {
  constructor(data) {
    this.order = data.order ? new Order(data.order) : null;
    this.sessionId = data.sessionId || null;
  }
}

class Order {
  constructor(data) {
    this.id = data.id || null;
    this.user_id = data.user_id || null;
    this.total_price = data.total_price || null;
    this.tax = data.tax || null;
    this.price_after_tax = data.price_after_tax || null;
    this.shipping_cost = data.shipping_cost || null;
    this.price_after_shipping = data.price_after_shipping || null;
    this.order_status = data.order_status || "pending";
    this.payment_method = data.payment_method || null;
    this.payment_method_id = data.payment_method_id || null;
    this.payment_status = data.payment_status || "unpaid";
    this.created_at = data.created_at || null;
    this.updated_at = data.updated_at || null;
    this.order_products = Array.isArray(data.order_products)
      ? data.order_products.map((product) => new OrderProduct(product))
      : [];
  }
}

class OrderProduct {
  constructor(data) {
    this.id = data.id || null;
    this.order_id = data.order_id || null;
    this.product_id = data.product_id || null;
    this.quantity = data.quantity || null;
    this.unit_price = data.unit_price || null;
    this.total_price = data.total_price || null;
    this.created_at = data.created_at || null;
    this.updated_at = data.updated_at || null;
    this.product = data.product ? new Product(data.product) : null;
  }
}
