export class ResetPasswordSuccess {
  constructor(message) {
    this.success = true;
    this.message = message;
  }
}

export class ResetPasswordFailure {
  constructor(message) {
    this.success = false;
    this.message = message;
  }
}
