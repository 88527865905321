export class User {
  constructor(
    id,
    first_name,
    last_name,
    email,
    number,
    agreed_privacy_policy,
    agreed_terms_of_use,
    created_at,
    updated_at
  ) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.number = number;
    this.agreed_privacy_policy = agreed_privacy_policy;
    this.agreed_terms_of_use = agreed_terms_of_use;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
}

export class SignupSuccess {
  constructor(success, user, token, message) {
    this.success = success;
    this.result = {
      user: new User(
        user.id,
        user.first_name,
        user.last_name,
        user.email,
        user.number,
        user.agreed_privacy_policy,
        user.agreed_terms_of_use,
        user.created_at,
        user.updated_at
      ),
      token,
    };
    this.message = message;
  }
}

export class SignupFailure {
  constructor(emailErrors, numberErrors, message) {
    this.success = false;
    this.email = emailErrors;
    this.number = numberErrors;
    this.message = message || "Signup failed due to validation errors";
  }
}
