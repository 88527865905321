import { ProductService } from "@/network/services/ProductService";
import { CheckoutSuccess } from "@/network/models/products/CheckoutSuccess";
import { CheckoutFailure } from "@/network/models/products/CheckoutFailure";

export const ProductController = {
  async fetchProduct(id) {
    try {
      const product = await ProductService.getProduct(id);
      return {
        success: true,
        product,
      };
    } catch (error) {
      console.error("Product retrieval failed:", error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async checkout(cartItems) {
    try {
      const formData = new FormData();

      cartItems.forEach((item, index) => {
        formData.append(`products[${index}][id]`, item.id);
        formData.append(`products[${index}][quantity]`, item.quantity);
      });

      const result = await ProductService.checkout(formData);

      if (result instanceof CheckoutSuccess) {
        return {
          success: true,
          message: result.message,
          order: result.result?.order || null,
          sessionId: result.result?.sessionId || null,
        };
      } else if (result instanceof CheckoutFailure) {
        return {
          success: false,
          message: result.message,
        };
      }
    } catch (error) {
      console.error("Checkout failed:", error);

      return {
        success: false,
        message: "An error occurred during checkout: " + error.message,
      };
    }
  },
  async fetchProducts(filters = {}) {
    try {
      const { category, tag, limit, only_hero_products, page = 1 } = filters; // Default page to 1
      const result = await ProductService.getProducts({
        category,
        tag,
        limit,
        only_hero_products,
        page, // Send page to the API
      });

      if (result.products) {
        return {
          success: true,
          products: result.products,
          pagination: result.pagination,
        };
      } else {
        return {
          success: false,
          message: result.message,
        };
      }
    } catch (error) {
      console.error("Product list retrieval failed:", error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async fetchSearchProducts() {
    try {
      const result = await ProductService.getSearchProducts();

      if (result.products) {
        return {
          success: true,
          products: result.products,
        };
      } else {
        return {
          success: false,
          message: result.message,
        };
      }
    } catch (error) {
      console.error("Product list retrieval failed:", error);
      return {
        success: false,
        message: error.message,
      };
    }
  },
};
